import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, LinkBox, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "60px 0 20px 0",
	"background": "--color-darkL1",
	"quarkly-title": "Footer-16"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 50px 0px",
			"md-margin": "0px 0px 45px 0px",
			"lg-flex-direction": "column"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"width": "20%",
			"lg-margin": "0px 0px 35px 0px",
			"lg-width": "100%",
			"href": "#"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--lead",
			"children": "EventExcellence"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "block",
			"width": "80%",
			"lg-width": "100%",
			"justify-content": "space-around",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"md-display": "flex",
			"md-grid-gap": "24px",
			"align-items": "center",
			"align-self": "center"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"margin": "0px 50px 0px 10px",
			"justify-content": "space-around",
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"sm-margin": "0px 0 30px 0",
			"gap": "16px 0",
			"sm-grid-template-columns": "1fr",
			"md-display": "flex",
			"md-grid-gap": "16px",
			"md-margin": "0px 50px 0px 0px",
			"align-content": "space-between"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"children": "Hizmetler",
			"href": "/team"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"children": "Şartlar ve Koşullar",
			"href": "/sartlar-ve-kosullar"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"children": "Gizlilik Politikası",
			"href": "/privacy"
		}
	}
};

const Header1 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" />
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Text {...override("text")} />
			</LinkBox>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header1, { ...Section,
	defaultProps,
	overrides
});
export default Header1;
import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Input, Button, Link, Section } from "@quarkly/widgets";
import QuarklycommunityKitNetlifyForm from "./QuarklycommunityKitNetlifyForm";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"position": "relative",
	"quarkly-title": "Form-4"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"z-index": "1",
			"left": "0px",
			"top": "0px",
			"right": "auto",
			"bottom": "auto",
			"position": "static",
			"grid-template-columns": "repeat(2, 1fr)"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"align-items": "center"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"lg-padding": "0px 0px 0px 0px",
			"sm-font": "normal 700 36px/1.2 --fontFamily-sans",
			"margin": "0px 0px 18px 0px",
			"font": "normal 600 42px/1.2 --fontFamily-sans",
			"color": "--darkL1",
			"text-align": "center",
			"padding": "0px 0 0px 0px",
			"lg-width": "100%",
			"lg-margin": "0px 0px 25px 0px",
			"children": "Geri bildirim"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0 50px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansHelvetica",
			"lg-margin": "0px 0 50px 0px",
			"text-align": "center",
			"display": "flex",
			"width": "600px",
			"sm-width": "auto",
			"md-width": "100%",
			"children": "Danışma veya rezervasyon için iletişim bilgilerinizi bırakın, kısa süre içinde sizinle iletişime geçelim"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"z-index": "1",
			"display": "flex",
			"lg-flex-direction": "column",
			"flex-direction": "column",
			"align-items": "center"
		}
	},
	"quarklycommunityKitNetlifyForm": {
		"kind": "QuarklycommunityKitNetlifyForm",
		"props": {
			"lg-margin": "0px 0px 35px 0px",
			"sm-margin": "0px 0px 25px 0px",
			"sm-width": "100%"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-flex-wrap": "wrap",
			"margin": "0px 0px 15px 0px",
			"flex-direction": "column"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "block",
			"margin": "0px 0px 20px 0px",
			"sm-flex-direction": "column",
			"sm-width": "100%"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"margin": "0px 0 0px 0px",
			"sm-margin": "0px 0 15px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"font": "normal 400 16px/1.5 --fontFamily-sans",
			"children": "İsim"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"margin": "0px 0 0px 0px",
			"padding": "12px 16px 12px 16px",
			"width": "100%",
			"font": "--lead",
			"md-max-width": "none",
			"border-radius": "8px",
			"name": "First Name",
			"type": "text",
			"required": true,
			"md-margin": "0px 0 16px 0px",
			"background": "rgba(255, 255, 255, 0.1)",
			"border-color": "--color-lightD2"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"margin": "0px 0px 0 0px",
			"sm-flex-direction": "column",
			"flex-direction": "column",
			"sm-width": "100%"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"margin": "0px 0px 20px 0px",
			"width": "100%"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"font": "normal 400 16px/1.5 --fontFamily-sans",
			"children": "E-posta"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"margin": "0px 0 0px 0px",
			"padding": "12px 16px 12px 16px",
			"width": "100%",
			"font": "--lead",
			"md-max-width": "none",
			"border-radius": "8px",
			"name": "E-mail",
			"type": "email",
			"required": true,
			"md-margin": "0px 0 16px 0px",
			"background": "rgba(255, 255, 255, 0.1)",
			"border-color": "--color-lightD2"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"margin": "0px 0px 20px 0px",
			"width": "100%"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"font": "normal 400 16px/1.5 --fontFamily-sans",
			"children": "Telefon numarası"
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"margin": "0px 0 0px 0px",
			"padding": "12px 16px 12px 16px",
			"width": "100%",
			"font": "--lead",
			"md-max-width": "none",
			"border-radius": "8px",
			"name": "Phone number",
			"type": "tel",
			"required": true,
			"md-margin": "0px 0 16px 0px",
			"background": "rgba(255, 255, 255, 0.1)",
			"border-color": "--color-lightD2"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"padding": "11px 24px 11px 24px",
			"font": "normal 400 20px/1.5 --fontFamily-sans",
			"sm-width": "100%",
			"focus-box-shadow": "none",
			"color": "--light",
			"background": "--color-darkL1",
			"border-radius": "8px",
			"hover-background": "--color-darkL1",
			"hover-color": "--light",
			"hover-transition": "background-color 0.5s ease 0s",
			"transition": "background-color 0.1s ease 0s",
			"margin": "0px 0px 15px 0px"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"sm-flex-direction": "column"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 5px 0px 0px",
			"font": "normal 400 18px/1.5 --fontFamily-sansHelvetica",
			"children": "Verilerinizi önemsiyoruz. Bizim okuyun"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "#",
			"color": "--darkL1",
			"font": "normal 400 18px/1.5 --fontFamily-sansHelvetica",
			"border-color": "--color-darkL1",
			"children": "GİZLİLİK POLİTİKASI"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" max-width="1220px" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<QuarklycommunityKitNetlifyForm {...override("quarklycommunityKitNetlifyForm")}>
					<Box {...override("box3")}>
						<Box {...override("box4")}>
							<Box {...override("box5")}>
								<Text {...override("text2")} />
								<Input {...override("input")} />
							</Box>
						</Box>
						<Box {...override("box6")}>
							<Box {...override("box7")}>
								<Text {...override("text3")} />
								<Input {...override("input1")} />
							</Box>
							<Box {...override("box8")}>
								<Text {...override("text4")} />
								<Input {...override("input2")} />
							</Box>
						</Box>
						<Button {...override("button")}>
							Gönder
						</Button>
						<Box {...override("box9")}>
							<Text {...override("text5")} />
							<Link {...override("link")} />
						</Box>
					</Box>
				</QuarklycommunityKitNetlifyForm>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;
import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Link, Span, Text, Section, Em } from "@quarkly/widgets";
const defaultProps = {
	"padding": "70px 0 70px 0",
	"sm-padding": "40px 0 40px 0",
	"background": "--color-darkL1",
	"quarkly-title": "Footer-20"
};
const overrides = {
	"link": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"display": "block",
			"margin": "0px 0 15px 0px",
			"sm-margin": "0px 0 5px 0px",
			"text-align": "center",
			"hover-color": "--primary",
			"href": "/index",
			"color": "--primary",
			"font": "--headline3",
			"children": "Güvenilir Etkinlik Yönetimi Ortağınız"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "tel:+9877654321223",
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 600 28px/1.2 --fontFamily-sans",
			"display": "block",
			"margin": "0px 0 15px 0px",
			"sm-margin": "0px 0 5px 0px",
			"text-align": "center",
			"hover-color": "--primary",
			"children": "+905322203396"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "--headline3",
			"display": "block",
			"margin": "0px 0 15px 0px",
			"sm-margin": "0px 0 5px 0px",
			"text-align": "center",
			"hover-color": "--primary",
			"href": "info@eventexcellence.com",
			"children": "info@eventexcellence.com"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"text-align": "center",
			"color": "--light",
			"sm-margin": "0px 0px 20px 0px",
			"font": "--headline3",
			"children": "Zuhuratbaba, instagram: istanbul.event.project, Sağlık Sk. No:12, 34147 Bakırköy/İstanbul, Türkiye"
		}
	}
};

const NewFooter1 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" align-items="center" justify-content="center" />
		<Link {...override("link")} />
		<Link {...override("link1")} />
		<Link {...override("link2")} />
		<Text {...override("text")} />
		{children}
	</Section>;
};

Object.assign(NewFooter1, { ...Section,
	defaultProps,
	overrides
});
export default NewFooter1;